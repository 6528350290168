<template>
  <div style="padding:10px;">
    <v-row>
      <v-col
        cols="12"
        class="py-2"
      >
        <h2>Barceló contenido común</h2>
      </v-col>
      <v-col
        cols="12"
        class="py-2"
      >
        <v-btn
          v-for="plan of config"
          :key="plan.id"
          :value="plan.id"
          :color="selectedPlan && selectedPlan.id === plan.id ? plan.color : 'white'"
          @click="handleSelectPlan(plan.id)"
          style="margin-right: 10px;"
          :dark="selectedPlan && selectedPlan.id === plan.id ? true : false"
        >
          {{ plan.id}}
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="selectedPlan">
      <v-col
        cols="8"
        class="py-2"
      >
        <list
          v-if="selectedPlan"
          :plan="selectedPlan"
          :locale="locale"
          :onEdit="(v) => { editedItem = JSON.parse(JSON.stringify(v)) }"
          :forceRefresh="forceRefresh"
        />
      </v-col>
      <v-col
        cols="4"
        class="py-2"
      >
        <event-form
          v-if="selectedPlan && editedItem"
          :plan="selectedPlan"
          :value="editedItem"
          :locale="locale"
          :onClose="handleCloseForm"
          :languages="languages"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import List from './List'
import EventForm from './Form'
export default {
  components: {
    List,
    EventForm,
  },
  data: () => ({
    config: null,
    selectedPlan: null,
    editedItem: null,
    forceRefresh: null,
    languages: null,
  }),
  computed: {
    ...mapState('app',['locale']),
  },
  mounted () {
    this.handleGetConfig()
  },
  methods: {
    async handleGetConfig () {
      api.getAll('dviewcontent', `v1/private/activity-barcelo-01/config`)
        .then((response) => {
          this.config = response
          if (this.config && this.config.length > 0) {
            this.handleSelectPlan('we')
          }
        })
    },
    handleSelectPlan (v) {
      this.editedItem = null
      this.selectedPlan = this.config.filter(x => x.id === v).shift()
    },
    handleCloseForm (refresh) {
      if (refresh) this.forceRefresh = new Date().toISOString()
      this.editedItem = null
    },
  }
}
</script>

